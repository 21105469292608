.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  color: #333;
}


.box {
    width: 40px;
    height: 40px;
    border: 2px solid #565758;
}

.correct {
    background-color: #6AAA64;
}

.partial {
    background-color: #D1B036;
}

.incorrect {
    background-color: #FF6D6D;
}

.empty {
    background-color: white;
}

.game-scene {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.box-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.boxes{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.clock{
    font-family: "Libre Franklin", sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.clock h2{
    margin: 0;
    padding: 5px;
    padding-bottom: 5px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 2px solid #D9D9D9;
    height: 50px;
}

.navbar-right {
    display: flex;
    gap: 10px;
}

.navbar-left{
    display: flex;
    gap: 10px;
}

.navbar .gap{
    width: 32px;
}

.navbar h2 {
    font-family: "Zen Maru Gothic", serif;
    font-weight: 900;
    font-style: normal;
}

.navbar svg {
    font-size: 2rem;
}

.navbar svg:hover {
    cursor: pointer;
}

.question-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.question {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 80%;
    gap: 20px;
}

.question-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 200px;
    padding: 0;
}

.question-column h3 {
    margin:0;
    margin-bottom: auto; /* Push the input to the bottom */
    padding: 0;
    padding-bottom: 20px;
    font-size: 16px;
}


.question-input {
    margin-top: 10px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    height: 33px;
    width: 100%;
    margin-top: auto; /* Snap the input to the bottom */
    text-align: center;
    font-size: 16px;
    -webkit-autocorrect: off;
    -webkit-text-size-adjust: none;    
}

.question-button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.gray-button {
    padding: 10px 20px;
    background-color: #CFCFCF;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-family: "Libre Franklin", sans-serif;
    font-weight: 600;
    width: 150px;
    height: 35px;
    color: #333;
}

/* .question-container button:hover {
    background-color: #BFBFBF;
} */

.small-break{
    margin-top: 10px;
}

.standard-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: #333;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    color: #fff;
    width: 180px;
    height: 40px;
}

/* .standard-button:hover {
    background-color: #444444;
} */

.button-icon {
    margin-right: 10px;
}

.modal{
    z-index: 1000;
    position: fixed;
    top: 30vh;
    left: 50%;
    width: calc(min(90%, 600px));
    transform: translate(-50%, -47%);
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}

.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.modal-container{
    cursor: default;
}

.modal-content{
    width: 90%;
    padding-top: 20px;
}

.minimal{
    padding: 0.25rem;
    padding: 0;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    border: none;
}

.minimal:hover{
    background-color: #f0f0f0;
}

.centered-icon-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-icon{
    padding-right: 5px;
}

.nav-header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    margin-left: 5px;
    margin-top: 5px;
}

.minimal-button{
    /* padding: 0.25rem; */
    padding: 0;
    margin: 0;
    font-size: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #ccc;
    outline: none;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    border: none;
    color: #333;
}


.animated-answer-left {
    animation: moveLeft 3s forwards;
}

.animated-answer-right {
    animation: moveRight 3s forwards;
}

@keyframes moveLeft {
    0% {
        margin-left: 0;
    }
    25% {
        margin-left: 0;
    }
    100% {
        margin-left: 10px;
    }
}

@keyframes moveRight {
    0% {
        margin-right: 0;
    }
    25% {
        margin-right: 0;
    }
    100% {
        margin-right: 10px;
    }
}

@keyframes removeGap {
    0% {
        gap: 20px;
    }
    25% {
        gap: 20px;
    }
    100% {
        gap: 0;
    }
}


.centered{
    display: flex;
    justify-content: center;
    align-items: center;
}

.game-start{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;

}

.answer-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    animation: removeGap 3s forwards;
}

.example-text{
    height: 40px;
    padding: 0px;
    margin: 0px;
    padding-top: 10px;
}

.highlight{
    color: green;
}

.close{
    padding-left: 5px;
    padding-top: 5px;
    color: #b8b8b8;
}

.question-container-help{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.question-help{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    gap: 20px;
}

.start-game{
    font-size: 16px;
    height: 50px;
    font-weight: 500;
}

.logo{
    height: 80px;
}

.title-card-header{
    font-size: 28px;
    padding-top: 0;
    margin-top: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.title-card-text{
    font-size: 16px;
    padding-top: 0;
    margin-top: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.header-name{
    cursor: pointer;
}

.question-answer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.question-answer-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    
}

.answer-key-q-header{
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
}

.answer-key-a-header{
    color: green;
    /* margin: 0; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.divider{
    border-top: 1px solid #D9D9D9;
    margin-top: 0px;
    margin-bottom: 20px;
}

.answer-key{
    margin-top: 30px;
    height: 375px;
    overflow-y: auto;
}

.answer-key-header{
    margin: 0;
}

.stats-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.stat-display{
    justify-content: left;
}

.stat-row{
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
    height: 60px;
}

.stat-row h1{
    width: 80px;
}

.primary {
    background-color: #3A8DFF;
    font-weight: 600;
}